<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Reports</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location">Reports</span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<validation-observer ref="simpleRules">
					<b-row>
						<!-- Date picker -->
						<b-col cols="12" md="6">
							<b-row>
								<b-col md="6">
									<label for="example-input">From Date</label>

									<b-form-datepicker
										v-model="fromDate"
										name="fromDate"
										placeholder="Choose a date"
										local="en"
									/>
								</b-col>
								<b-col md="6">
									<label for="example-input">To Date</label>
									<b-form-datepicker
										v-model="toDate"
										name="toDate"
										placeholder="Choose a date"
										local="en"
									/>
								</b-col>
							</b-row>
						</b-col>
						<b-col cols="12" md="6">
							<b-row>
								<b-col md="6" />

								<b-col md="6">
									<label for="example-input"> Hatch Id</label>
									<validation-provider
										#default="{ errors }"
										name="Hatch Id"
										rules="required"
									>
										<b-form-select
											v-model="selected"
											:options="hatchList"
											text-field="hatch_id"
											value-field="hatch_id"
											v-on:change="getSelectedHatchId"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</validation-observer>
			</div>
		</b-card>
		<b-card>
			<b-row class="report-view">
				<b-col
					md="6"
					v-on:click="internalReport()"
					class="d-flex justify-content-center flex-column align-items-center"
				>
					<div class="circle">
						<b-img
							:src="require('@/assets/images/svg/reports/internal-report.svg')"
							style="width: 70px; height: 70px"
						/>
					</div>
					<h3 class="mt-5 ml-2 textInfo">Internal Report</h3>
				</b-col>
				<b-col
					md="6"
					class="d-flex justify-content-center flex-column align-items-center"
					v-on:click="externalReport()"
				>
					<div class="circle">
						<b-img
							:src="require('@/assets/images/svg/reports/economy-report.svg')"
							style="width: 70px; height: 70px"
						/>
					</div>
					<h3 class="mt-5 ml-2 textInfo">Economy Report</h3></b-col
				>
			</b-row>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";

import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,
		vSelect,
		BFormDatepicker,
		BFormSelect,
		ValidationProvider,
		ValidationObserver,
		BImg,
	},
	data() {
		return {
			selected: null,
			fromDate: "",
			toDate: "",
			hatchList: [],
		};
	},
	created: function () {
		this.getHatchList();
	},
	methods: {
		getHatchList() {
			const hatchery_id = localStorage.getItem("hatchery_id");
			axiosIns
				.get(`web/hatcheries/${hatchery_id}/get-hatcher-hatch-list`)
				.then((response) => {
					this.hatchList = response.data.hatchItems;
				})
				.catch((error) => {
					error;
				});
		},

		getSelectedHatchId(hatch_id) {
			this.selected = hatch_id;
		},
		internalReport() {
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.$router.push({
						name: `apps-reports-internal`,
					});
				}
			});
		},
		externalReport() {
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.$router.push({
						name: `apps-reports-external`,
					});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.report-view {
	background-color: #f7f7f7;
	border-radius: 5px;
	padding: 2rem 2rem;
	margin: 3rem 3rem;
	height: 400px;
}
.circle {
	width: 195px;
	height: 195px;
	border-radius: 50%;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid #00c071;
	opacity: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}
.textInfo {
	color: #38c06c;
	font-size: 20px;
	font-weight: 600;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
